/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_share: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M18 1c2.21 0 4 1.79 4 4a3.999 3.999 0 01-6.841 2.815L9.85 10.912c.098.346.15.71.15 1.088 0 .377-.052.741-.15 1.087l5.312 3.095A3.999 3.999 0 0122 19c0 2.21-1.79 4-4 4a3.999 3.999 0 01-3.85-5.092l-5.309-3.093A3.999 3.999 0 012 12a3.999 3.999 0 016.841-2.815l5.309-3.097A3.999 3.999 0 0118 1zm0 16c-.695 0-1.308.355-1.666.894l-.05.1a.911.911 0 01-.065.097l-.033.066A2 2 0 1018 17zM6 10a2 2 0 101.716 3.027l.01-.02.024-.038c.16-.287.25-.618.25-.969a1.99 1.99 0 00-.254-.976l-.02-.03-.01-.017A1.99 1.99 0 006 10zm12-7a2 2 0 00-1.75 2.969l.024.037.01.017A2 2 0 1018 3z"/></g>'
      },
      ic_download: {
        viewbox: '0 0 20 22',
        content: '<path d="M19 15a1 1 0 011 1v3a3 3 0 01-3 3H3a3 3 0 01-3-3v-3a1 1 0 012 0v3a1 1 0 001 1h14a1 1 0 001-1v-3a1 1 0 011-1zM10 0a1 1 0 01.993.883L11 1v11.585l2.293-2.292a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-4 4-.044.042-.068.055-.11.071-.114.054-.105.035-.118.025-.089.01h-.118l-.06-.006-.115-.02-.108-.033-.081-.034-.098-.052-.096-.067a.97.97 0 01-.09-.08l-4-4a1 1 0 011.32-1.497l.094.083L9 12.585V1a1 1 0 01.883-.993L10 0z" fill="currentColor" fill-rule="nonzero"/>'
      },
      ic_collections: {
        viewbox: '0 0 16 16',
        content: '<g fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.6289 15.12C13.6805 15.12 15.3436 13.4568 15.3436 11.4052C15.3436 9.3536 13.6805 7.69043 11.6289 7.69043C9.57723 7.69043 7.91406 9.3536 7.91406 11.4052C7.91406 13.4568 9.57723 15.12 11.6289 15.12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.6289 9.54785V13.2626" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.4862 11.4053H9.77148" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.29479 15.12H1.72264C1.38071 15.12 1.10352 14.8428 1.10352 14.5009V1.49913C1.10352 1.1572 1.38071 0.880005 1.72264 0.880005H9.95278C10.1169 0.880037 10.2744 0.945291 10.3905 1.06141L12.6856 3.35653C12.8017 3.47261 12.867 3.63006 12.867 3.79425V5.83305" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g>'
      },
      ic_view_module : {
        viewbox: '0 0 24 24',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">  <g clip-path="url(#clip0_6027_13449)">  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.799805 17.52C0.799805 16.9898 1.22961 16.56 1.7598 16.56H9.4398C9.97004 16.56 10.3998 16.9898 10.3998 17.52V22.32C10.3998 22.8502 9.97004 23.28 9.4398 23.28H1.7598C1.22961 23.28 0.799805 22.8502 0.799805 22.32V17.52Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8803 22.32C22.8803 22.8502 22.4505 23.28 21.9203 23.28H14.2403C13.7101 23.28 13.2803 22.8502 13.2803 22.32V11.76C13.2803 11.2298 13.7101 10.8 14.2403 10.8H21.9203C22.4505 10.8 22.8803 11.2298 22.8803 11.76V22.32Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8803 6.96001C22.8803 7.4902 22.4505 7.92001 21.9203 7.92001H14.2403C13.7101 7.92001 13.2803 7.4902 13.2803 6.96001V2.16001C13.2803 1.62982 13.7101 1.20001 14.2403 1.20001H21.9203C22.4505 1.20001 22.8803 1.62982 22.8803 2.16001V6.96001Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.799805 2.16001C0.799805 1.62982 1.22961 1.20001 1.7598 1.20001H9.4398C9.97004 1.20001 10.3998 1.62982 10.3998 2.16001V12.72C10.3998 13.2502 9.97004 13.68 9.4398 13.68H1.7598C1.22961 13.68 0.799805 13.2502 0.799805 12.72V2.16001Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  </g>  <defs><clipPath id="clip0_6027_13449">  <rect width="24" height="24" fill="white"/>  </clipPath>  </defs>  </svg>'
      },
      ic_view_stream: {
        viewbox: '0 0 24 24',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8.56396 5.03809H22.4101" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.56396 12.7181H22.4101" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.56396 20.3981H22.4101" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1.17969 4.07807C1.17969 3.54787 1.59297 3.11807 2.10276 3.11807H3.94892C4.45872 3.11807 4.87199 3.54787 4.87199 4.07807V5.99807C4.87199 6.52826 4.45872 6.95807 3.94892 6.95807H2.10276C1.59297 6.95807 1.17969 6.52826 1.17969 5.99807V4.07807Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.17969 11.7581C1.17969 11.2279 1.59297 10.7981 2.10276 10.7981H3.94892C4.45872 10.7981 4.87199 11.2279 4.87199 11.7581V13.6781C4.87199 14.2083 4.45872 14.6381 3.94892 14.6381H2.10276C1.59297 14.6381 1.17969 14.2083 1.17969 13.6781V11.7581Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.17969 19.4381C1.17969 18.9079 1.59297 18.4781 2.10276 18.4781H3.94892C4.45872 18.4781 4.87199 18.9079 4.87199 19.4381V21.3581C4.87199 21.8883 4.45872 22.3181 3.94892 22.3181H2.10276C1.59297 22.3181 1.17969 21.8883 1.17969 21.3581V19.4381Z" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>'
      },
      ic_tune: {
        viewbox: '0 0 16 16',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.5211 1.9077C14.6665 1.72122 14.6899 1.47174 14.5815 1.26326C14.4731 1.05477 14.2515 0.92308 14.0091 0.92308H2.47692C2.2345 0.92308 2.01289 1.05477 1.90448 1.26326C1.79607 1.47174 1.81947 1.72122 1.96492 1.9077L6.96201 8.3637V14.4615C6.96201 14.6947 7.09897 14.9077 7.3158 15.0119C7.53264 15.1162 7.79209 15.0937 7.98601 14.9538L9.26601 14.0308C9.42716 13.9145 9.52201 13.7322 9.52201 13.5385V8.36308L14.5211 1.9077Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      },
      ic_account_circle: {
        viewbox: '0 0 16 16',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g clip-path="url(#clip0_6027_14020)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.99984 15.3333C12.0499 15.3333 15.3332 12.05 15.3332 7.99996C15.3332 3.94987 12.0499 0.666626 7.99984 0.666626C3.94975 0.666626 0.666504 3.94987 0.666504 7.99996C0.666504 12.05 3.94975 15.3333 7.99984 15.3333Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00021 8.63769C9.49694 8.63769 10.7103 7.42435 10.7103 5.92755C10.7103 4.43078 9.49694 3.21741 8.00021 3.21741C6.50341 3.21741 5.29004 4.43078 5.29004 5.92755C5.29004 7.42435 6.50341 8.63769 8.00021 8.63769Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.0281 12.1449C11.2915 10.5874 9.72285 9.59412 7.99992 9.59412C6.27695 9.59412 4.70838 10.5874 3.97168 12.1449" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_6027_14020"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>'
      },
      ic_exit_to_app: {
        viewbox: '0 0 16 16',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g clip-path="url(#clip0_6027_14027)"><path d="M9.06655 3.69233V1.53848C9.06655 1.19862 8.78002 0.923096 8.42655 0.923096H1.38658C1.03312 0.923096 0.746582 1.19862 0.746582 1.53848V14.4616C0.746582 14.8014 1.03312 15.0769 1.38658 15.0769H8.42655C8.78002 15.0769 9.06655 14.8014 9.06655 14.4616V12.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.4666 8H3.30664" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.2666 11.0769L15.4666 8.00002L12.2666 4.9231" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_6027_14027"><rect width="16" height="16" fill="white"/></clipPath>  </defs></svg>'
      },
    }
  }
}
